<template>
    <div>
        <b-container>
            <b-row>
                <b-col md="7">
                    <h3>Privat.</h3>
                    <p>
                        Har du ett badrum, kök eller något annat som behöver fixas på huset? Vi på Rato har hjälpt privatpersoner sedan 2007 med alla olika typer av arbeten allt från, hela husbyggen till mindre renoveringar.<br>
                        <br>
                        Vi har certifieringarna som krävs för att du som kund skall kunna känna dig trygg och säker och självklart kan du använda ditt ROT-avdrag vid våra arbeten!<br>
                        <br>
                        Kontakta oss via frågeformuläret så hjälper vi er.<br>
                        <br>
                        Telefon: <a href="tel:+46734444003">+46 73 444 4003</a><br>
                    </p>
                </b-col>
                <b-col md="5">
                    <contact-form/>
                </b-col>

<!--                Certifications-->
                <b-col cols="12" class="mt-5">
                    <b-row align-v="center">
                        <b-col cols="4" class="item">
                            <img src="@/assets/images/qhytn08zi4whfngmmxpm.jpg" alt="AAA" class="img-fluid certification-logo">
                        </b-col>
                        <b-col cols="4" class="item">
                            <img src="@/assets/images/di_gasell_new2015_FB.png" alt="Di Gasell" class="img-fluid certification-logo">
                        </b-col>
                        <b-col cols="4" class="item">
                            <img src="@/assets/images/logotyp-per.jpg" alt="PER" class="img-fluid certification-logo">
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>
        <our-location class="mt-5"/>
    </div>
</template>

<script>
    import ContactForm from "../ContactForm";
    import OurLocation from "../OurLocation";
    export default {
        name: "AboutPrivate",
        components: {OurLocation, ContactForm}
    }
</script>

<style scoped>
    .item {
        text-align: center;
        margin-bottom: 40px;
    }

    .certification-logo {
        width: 150px;
        max-height: 150px;
    }
</style>
