<template>
    <div>
        <hero image="https://api.ratoab.se/HeroImages?location=1"
              :dark-background="false"
              style="height: 60vh"
        />

        <about-private class="mt-5"/>
    </div>
</template>

<script>
    import Hero from "../../components/Hero";
    import AboutPrivate from "../../components/Services/AboutPrivate";
    export default {
        name: "Private",
        components: {AboutPrivate, Hero},
        mounted () {
            window.scrollTo(0, 0)
        }
    }
</script>

<style scoped>

</style>
